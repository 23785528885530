import React, { useEffect } from "react";
import {
  Table,
  Typography,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button,
  TextField,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Autocomplete,
  Pagination
} from "@mui/material";
import RoleIcon from "@mui/icons-material/AccountCircle";
import EditIcon from "@mui/icons-material/Edit";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import PermissionsIcon from "@mui/icons-material/PlaylistAddCheck";
import { Routes, Route, Link as RouteLink, useNavigate, useParams } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
  CreateRoleInput,
  RoleType,
  RolePermissionType,
  CustomError,
  OrganizationType,
  ResellerType,
} from "../services/interfaces";
import {
  GlobalReducer,
  Reducers,
  RolesPageReducer,
  UpdateRoleReducer,
} from "../reducers";
import * as roleActions from "../actions/roles";
import Permissioned, { actions, resources } from "../permissioned";
import * as prodActions from "../actions/orgLevel/productSubscriptions";
import ConfirmButton from "../components/confirmButton";
import MandatoryFieldText from "../components/mandatoryFieldsText";
import { styled } from "@mui/system";
import * as contractActions from "../actions/autnhive/contracts";
import { CreateNewCustomerContractReducer } from "../reducers/autnhive";
import { OrgLevelProductsReducer } from "../reducers/orgLevel";

interface RoleFormProps {
  error?: CustomError;
  loading: boolean;
  onSubmit: Function;
  name?: string;
  role_for?: string;
  values?: CreateRoleInput;
  product?: { id: string, name: string};
  hiddenFields?: Array<string>;
}

interface RolePermissionsProps {
  role?: RoleType;
  loading: boolean;
  permissions: Array<RolePermissionType>;
  fetchRolePermissions: Function;
  toggleRolePermissionStatus: Function;
}

export function RoleForm(props: RoleFormProps) {
  const dispatch = useDispatch<any>();
  const rolesPage = useSelector<Reducers, RolesPageReducer>(
    (state) => state.rolesPage
  );
  const createContract = useSelector<
      Reducers,
      CreateNewCustomerContractReducer
    >((state) => state.autnhive.createNewCustomerContract);
  const subscriptions = useSelector<Reducers, OrgLevelProductsReducer>(
      (state) => state.orgLevel.products
    );
  const [name, setName] = React.useState<string>("");
  const [organization, setOrganization] =
    React.useState<OrganizationType | null>(null);
  const [reseller, setReseller] = React.useState<ResellerType | null>(null);
  const [roleFor, setRoleFor] = React.useState<string>("corporate");
  const [productId, setProductId] = React.useState<string>("");
  const [isEdit, setIsEdit] = React.useState(false)
  const [searchOrganizationQueryInput, setSearchOrganizationQueryInput] =
    React.useState("");
  const [searchResellerQueryInput, setSearchResellerQueryInput] =
    React.useState("");
  const globalState: GlobalReducer = useSelector<Reducers, GlobalReducer>(
    (state) => state.globe
  );
  const hiddenFields = props.hiddenFields || [];
  useEffect(() => {
    dispatch(
      roleActions.searchOrganizations(searchOrganizationQueryInput, roleFor)
    );
  }, [dispatch, organization, roleFor, searchOrganizationQueryInput]);

  useEffect(() => {
    dispatch(roleActions.searchResellers(searchResellerQueryInput));
  }, [dispatch, reseller, searchResellerQueryInput]);

  useEffect(() => {
    if (props.values?.name) {
      setName(props.values.name);
    }
  }, [props.values?.name]);

  useEffect(() => {
    if (props.product != null) {
      setProductId(props.product?.id);
      setIsEdit(true)
    }
  }, [props.product]);

  useEffect(() => {
      dispatch(contractActions.fetchProducts())
    }, [dispatch]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        let role_for = roleFor;
        if (globalState.userProfile?.user.role.role_for !== "autnhive") {
          role_for = globalState.userProfile?.user.role.role_for || "corporate";
        }
        props.onSubmit({
          name,
          role_for,
          organization_id:
            role_for === "home" || role_for === "corporate"
              ? organization?.id
              : null,
          reseller_id: role_for === "reseller" ? reseller?.id : null,
          product_id: productId
        });
      }}
    >
      <div>
        <TextField
          margin="normal"
          variant="standard"
          fullWidth
          error={!!props.error?.validationErrors?.name}
          required
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      {globalState.userProfile?.user.role.role_for === "autnhive" &&
        !hiddenFields.includes("is_autnhive") && (
          <div>
            <FormControl margin="normal" fullWidth>
              <InputLabel variant="standard" id="role-for">Role for</InputLabel>
              <Select
                variant="standard"
                labelId="role-for"
                id="role-for"
                value={roleFor}
                label="Age"
                onChange={(e) => {
                  setRoleFor(e.target.value as string);
                  setOrganization(null);
                }}
              >
                <MenuItem value="autnhive">AutnHive</MenuItem>
                <MenuItem value="corporate">Corporate</MenuItem>
                <MenuItem value="home">Home</MenuItem>
                <MenuItem value="reseller">Reseller</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
      {!hiddenFields.includes("organization_id") ? (
        <Permissioned resource={resources.Organizations} action={actions.Read}>
          <Autocomplete
            disabled={roleFor !== "corporate" && roleFor !== "home"}
            clearOnEscape
            value={organization}
            size="small"
            onChange={(event: any, newValue: OrganizationType | null) => {
              // if (newValue?.id) {
              //   dispatch(roleActions.selectOrganization(newValue.id));
              // }
              setOrganization(newValue);
              dispatch(prodActions.fetchProductSubscriptions(newValue?.id || "", 1, LIMIT))
              
            }}
            inputValue={searchOrganizationQueryInput}
            onInputChange={(event, newInputValue) => {
              setSearchOrganizationQueryInput(newInputValue);
            }}
            id="organizations"
            noOptionsText="No suggestions"
            options={rolesPage.organizations}
            style={{ width: 300 }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search"
                label="Organization"
                margin="normal"
                variant="standard"
              />
            )}
            renderOption={(options: any) => (
              <TextField
                variant="standard"
                margin="none"
                {...options}
                InputProps={{ disableUnderline: true }}
                value={options.key}
              />
            )}
          />
        </Permissioned>
      ) : null}
      {!hiddenFields.includes("reseller_id") ? (
        <Permissioned resource={resources.Resellers} action={actions.Read}>
          <Autocomplete
            disabled={roleFor !== "reseller"}
            clearOnEscape
            value={reseller}
            size="small"
            onChange={(event: any, newValue: ResellerType | null) => {
              // if (newValue?.id) {
              //   dispatch(roleActions.selectReseller(newValue.id));
              // }
              setReseller(newValue);
            }}
            inputValue={searchResellerQueryInput}
            onInputChange={(event, newInputValue) => {
              setSearchResellerQueryInput(newInputValue);
            }}
            id="resellers"
            noOptionsText="No suggestions"
            options={rolesPage.resellers}
            style={{ width: 300 }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} placeholder="Search" label="Reseller" margin="normal" variant="standard" />
            )}
            renderOption={(options: any) => (
              <TextField
                variant="standard"
                margin="none"
                {...options}
                InputProps={{ disableUnderline: true }}
                value={options.key}
              />
            )}
          />
        </Permissioned>
      ) : null}
      <div>
            <FormControl margin="normal" fullWidth>
              <InputLabel variant="standard" id="role-for">Product</InputLabel>
              <Select
                variant="standard"
                labelId="role-for"
                id="role-for"
                disabled={isEdit}
                value={productId}
                label="Age"
                onChange={(e) => {
                  setProductId(e.target.value as string);
                }}
              >
                {!organization ? createContract.products?.map((prod:any) => {
                  return <MenuItem value={prod.id}>{prod.name}</MenuItem>
                }) : subscriptions.subscriptions?.map((sub:any) => {
                  return <MenuItem value={sub.product.id}>{sub.product.name}</MenuItem>
                })}
              </Select>
            </FormControl>
          </div>
      <br />
      <MandatoryFieldText hasErrors={Boolean(props.error?.validationErrors)} />
      <div>
        <br />
        <Button type="submit" color="primary" variant="contained">
          Submit
        </Button>
      </div>
    </form>
  );
}

const LIMIT = 20;
export function RolesList() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const rolesPage: RolesPageReducer = useSelector<Reducers, RolesPageReducer>(
    (state) => state.rolesPage
  );
  useEffect(() => {
    dispatch(roleActions.fetchRoles(LIMIT, 1));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return <>
    <List>
      {rolesPage.roles.map((role: RoleType, index) => {
        return (
          <ListItem
            key={role.id}
            sx={{
              backgroundColor: index % 2 === 0 ? "#f1f1f1" : "inherit"
            }}
          >
            <ListItemIcon>
              <RoleIcon
                color={role.role_for === "autnhive" ? "primary" : undefined}
              />
            </ListItemIcon>
            <ListItemText
              primary={role.name}
              secondary={`
                  ${role.role_for === "autnhive" ? "AutnHive" : ""}
                  ${role.is_builtin ? "Built-in role" : ""}
                  ${role.organization?.name
                  ? `${role.organization?.name} (${role.organization?.organization_type})`
                  : ""
                }
                  ${role.reseller ? `${role.reseller.name} (reseller)` : ""}
                  `}
            />
            <ListItemSecondaryAction>
              <Permissioned
                resource={resources.QboardRoles}
                action={actions.Update}
              >
                {role.is_builtin ? (
                  <IconButton disabled={true} size="large">
                    <EditIcon />
                  </IconButton>
                ) : (
                  <RouteLink to={`/admin/roles/${role.id}/edit`}>
                    <Tooltip title="Edit role">
                      <IconButton size="large">
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </RouteLink>
                )}
              </Permissioned>
              <Permissioned
                resource={resources.QboardRoles}
                action={actions.Delete}
              >
                <Tooltip title="Delete Role">
                  <>
                    <ConfirmButton
                      heading="Confirm"
                      message="Do you want to delete this role?"
                      disabled={role.is_builtin}
                      component={IconButton}
                      onClick={() => {
                        Promise.resolve()
                          .then(() =>
                            dispatch(roleActions.deleteRole(role.id))
                          )
                          .then(() =>
                            dispatch(roleActions.fetchRoles(LIMIT, 1))
                          )
                          .then(() =>
                            enqueueSnackbar("Role deleted", {
                              variant: "success",
                            })
                          )
                          .catch((err: CustomError) =>
                            enqueueSnackbar(err.message, { variant: "error" })
                          );
                      }}
                    >
                      <DeleteIcon />
                    </ConfirmButton>
                  </>
                </Tooltip>
              </Permissioned>
              <Permissioned
                resource={resources.QboardRolesPermisions}
                action={actions.Read}
              >
                <RouteLink to={`/admin/roles/${role.id}/permissions`}>
                  <Tooltip title="Permissions">
                    <IconButton size="large">
                      <PermissionsIcon />
                    </IconButton>
                  </Tooltip>
                </RouteLink>
              </Permissioned>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
    <Pagination
      count={Math.ceil(rolesPage.count / LIMIT)}
      page={rolesPage.page}
      variant="outlined"
      shape="rounded"
      onChange={(e: React.ChangeEvent<unknown>, page: number) => {
        dispatch(roleActions.fetchRoles(LIMIT, page));
      }}
    />
  </>;
}

class RolePermissions extends React.Component<RolePermissionsProps> {
  componentDidMount() {
    this.props.fetchRolePermissions(
      this.props.role?.role_for,
      this.props.role?.id
    );
  }

  toggleState = (rolePermission: RolePermissionType) => {
    this.props.toggleRolePermissionStatus(
      this.props.role?.role_for,
      this.props.role?.id,
      rolePermission.permission.resource,
      rolePermission.permission.action,
      !rolePermission.is_active
    );
  };

  render() {
    const tableRows: Array<any> = [];
    this.props.permissions.forEach((row: RolePermissionType) => {
      const checkboxColor = row.permission.is_autnhive ? "#e87200" : "black";
      const roleFor = this.props.role?.role_for;
      if (!roleFor) {
        // if (!roleFor || !row.permission.permission_for.includes(roleFor)) {
        return;
      }
      tableRows.push(
        <TableRow key={row.permission.id}>
          <TableCell component="th" scope="row">
            <div>
              {row.permission.resource}
              <br />
              {row.permission.description}
            </div>
          </TableCell>
          <TableCell>{row.permission.action}</TableCell>
          <TableCell>
            <Permissioned
              resource={resources.QboardRolesPermisions}
              action={actions.UpdateStatus}
              alternateView={
                <>
                  {row.is_active ? (
                    <CheckBoxIcon style={{ color: "gray" }} />
                  ) : (
                    <CheckBoxOutlineBlankIcon style={{ color: "gray" }} />
                  )}
                </>
              }
            >
              <IconButton
                disabled={this.props.role?.is_builtin}
                color="secondary"
                onClick={() => this.toggleState(row)}
                size="large">
                {row.is_active ? (
                  <CheckBoxIcon style={{ color: checkboxColor }} />
                ) : (
                  <CheckBoxOutlineBlankIcon style={{ color: checkboxColor }} />
                )}
              </IconButton>
            </Permissioned>
          </TableCell>
        </TableRow>
      );
    });

    return (
      <React.Fragment>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Resource</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tableRows}</TableBody>
        </Table>
      </React.Fragment>
    );
  }
}

function EditModal(props: any) {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const updateRole: UpdateRoleReducer = useSelector<
    Reducers,
    UpdateRoleReducer
  >((state) => state.updateRole);
  const rolesPage: RolesPageReducer = useSelector<Reducers, RolesPageReducer>(
    (state) => state.rolesPage
  );
  interface Params {
    roleId: string
  }
  const { roleId } = useParams<keyof Params>() as Params;
  const role = rolesPage.roles.find((r) => r.id === roleId);
  return (
    <Dialog open={true} onClose={() => navigate(-1)}>
      <DialogTitle>Update Role - {role?.name}</DialogTitle>
      <DialogContent>
        <RoleForm
          values={role}
          hiddenFields={[
            "is_autnhive",
            "organization_id",
            "reseller_id",
          ]}
          error={updateRole.error}
          product={role?.product}
          onSubmit={(values: any) => {
            Promise.resolve()
              .then(() =>
                dispatch(roleActions.updateRole(roleId, values))
              )
              .then(() => navigate(-1))
              .then(() => dispatch(roleActions.fetchRoles(LIMIT, 1)))
              .then(() =>
                enqueueSnackbar("Role updated", { variant: "success" })
              )
              .catch((err: CustomError) =>
                enqueueSnackbar(err.message, { variant: "error" })
              );
          }}
          loading={updateRole.loading}
        />
      </DialogContent>
    </Dialog>
  );
}

function PermissionsModal(props: Reducers & DispatchActions) {
  const navigate = useNavigate();
  const { rolePermissions } = props;
  const rolesPage: RolesPageReducer = useSelector<Reducers, RolesPageReducer>(
    (state) => state.rolesPage
  );
  interface Params {
    roleId: string
  }
  const { roleId } = useParams<keyof Params>() as Params;
  const role = rolesPage.roles.find(
    (r) => r.id === roleId
  );
  return (
    <Dialog open={true} onClose={() => navigate(-1)}>
      <DialogTitle>Permissions - {role?.name}</DialogTitle>
      <DialogContent>
        <RolePermissions
          role={role}
          loading={rolePermissions.loading}
          permissions={rolePermissions.permissions}
          toggleRolePermissionStatus={props.toggleRolePermissionStatus}
          fetchRolePermissions={props.fetchRolePermissions}
        />
      </DialogContent>
    </Dialog>
  );
}

function Roles(props: Reducers & DispatchActions) {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [value, setValue] = React.useState<RoleType | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [valueOrg, setValueOrg] = React.useState<OrganizationType | null>(null);
  const [inputValueOrg, setInputValueOrg] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();
  const updateRole: UpdateRoleReducer = useSelector<
    Reducers,
    UpdateRoleReducer
  >((state) => state.updateRole);
  const rolesPage: RolesPageReducer = useSelector<Reducers, RolesPageReducer>(
    (state) => state.rolesPage
  );
  return (
    <React.Fragment>
      <Typography component="h1" variant="h5">
        Roles
      </Typography>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Permissioned resource={resources.QboardRoles} action={actions.Create}>
            <RouteLink to="/admin/roles/new">
              <Button
                color="secondary"
                size="small"
                variant="outlined"
                startIcon={<RoleIcon />}
              >
                New Role
              </Button>
            </RouteLink>
          </Permissioned>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <InputLabel id="status">Search Type</InputLabel>
            <Select
              fullWidth
              id="status"
              variant="standard"
              style={{ minWidth: "100px" }}
              value={rolesPage.searchType}
              onChange={(e: any) => {
                dispatch(roleActions.setRoleSearchType(e.target.value));
                if (e.target.value === 'all-roles') {
                  dispatch(roleActions.fetchRoles(LIMIT, 1));
                  setInputValue('');
                  setInputValueOrg('');
                  setValue(null);
                  setValueOrg(null);
                }else if(e.target.value === 'organizations') {
                  setInputValue('');
                  setValue(null);
                } else {
                  setInputValueOrg('');
                  setValueOrg(null);
                }
              }}
            >
              <MenuItem value="all-roles">All Roles</MenuItem>
              <MenuItem value="organization">Organization</MenuItem>
              <MenuItem value="role-name">Role Name</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {rolesPage.searchType === 'organization' ?
          <Grid item>
          <Permissioned
            resource={resources.QboardRoles}
            action={actions.Read}
          >
            <Autocomplete
              value={valueOrg}
              size="small"
              onChange={(event: any, newValue: OrganizationType | null) => {
                if (newValue?.id) {
                  dispatch(roleActions.fetchRoles(LIMIT, 1, { organization_id: newValue.id }));
                }
                if (!newValue) {
                  dispatch(roleActions.fetchRoles(LIMIT, 1));
                  dispatch(roleActions.setRoleSearchType('all-roles'));
                }
                setValueOrg(newValue);
              }}
              inputValue={inputValueOrg}
              onInputChange={(event, newInputValue) => {
                setInputValueOrg(newInputValue);
                dispatch(roleActions.searchOrganizations(newInputValue))
              }}
              id="rolesSearchOrg"
              noOptionsText="No suggestions"
              options={rolesPage.organizations}
              style={{ width: 300 }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search"
                  label="Organization"
                  variant="outlined"
                />
              )}
              renderOption={(options: any) => (
                <TextField
                  variant="standard"
                  margin="none"
                  {...options}
                  InputProps={{ disableUnderline: true }}
                  value={options.key}
                  key={options.id}
                />
              )}
            />
          </Permissioned>
        </Grid>
        : null}
        {rolesPage.searchType === 'role-name' ?
          <Grid item>
            <Permissioned
              resource={resources.Roles}
              action={actions.Read}
            >
              <Autocomplete
                value={value}
                size="small"
                onChange={(event: any, newValue: RoleType | null) => {
                  if (newValue?.id) {
                    dispatch(roleActions.fetchRoles(LIMIT, 1, { role_id: newValue.id }));
                  }
                  if (!newValue) {
                    dispatch(roleActions.fetchRoles(LIMIT, 1));
                    dispatch(roleActions.setRoleSearchType('all-roles'));
                  }
                  setValue(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                  if (newInputValue.length < 3) return;
                  dispatch(roleActions.getFilteredResults(LIMIT, 1, newInputValue));
                }}
                id="rolesSearch"
                noOptionsText="No suggestions"
                options={rolesPage.searchResults}
                style={{ width: 300 }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search"
                    label="Role Name"
                    variant="outlined"
                  />
                )}
                renderOption={(options: any) => (
                  <TextField
                    variant="standard"
                    margin="none"
                    {...options}
                    InputProps={{ disableUnderline: true }}
                    value={options.key}
                    key={options.id}
                  />
                )}
              />
            </Permissioned>
          </Grid>
          : null}
      </Grid>
      <Routes>
        <Route path="/new" element={
          <Dialog open={true} onClose={() => navigate(-1)}>
            <DialogTitle>Create Role</DialogTitle>
            <DialogContent>
              <RoleForm
                error={props.createRole.error}
                onSubmit={(values: any) => {
                  Promise.resolve()
                    .then(() => dispatch(roleActions.createRole(values)))
                    .then(() => navigate(-1))
                    .then(() => dispatch(roleActions.fetchRoles(LIMIT, 1)))
                    .then(() =>
                      enqueueSnackbar("Role created", { variant: "success" })
                    )
                    .catch((err: CustomError) =>
                      enqueueSnackbar(err.message, { variant: "error" })
                    );
                }}
                loading={props.createRole.loading}
              />
            </DialogContent>
          </Dialog>
        } />

        <Route path="/:roleId/edit" element={<EditModal />} />

        <Route path="/:roleId/permissions" element={<PermissionsModal {...props} />} />
      </Routes>
      <RolesList />
    </React.Fragment>
  );
}

interface DispatchActions {
  fetchRoles: Function;
  submitRole: Function;
  dismissSubmitRoleMessages: Function;
  fetchRolePermissions: Function;
  toggleRolePermissionStatus: Function;
}

const mapStateToProps = (reducers: Reducers) => reducers;
const mapDispatchToProps = (dispatch: any): DispatchActions => {
  return {
    fetchRoles: (limit: number, offset: number) =>
      dispatch(roleActions.fetchRoles(limit, offset)),
    submitRole: (values: CreateRoleInput) =>
      dispatch(roleActions.createRole(values)),
    dismissSubmitRoleMessages: () =>
      dispatch(roleActions.dismissCreateRoleMessages()),
    fetchRolePermissions: (permission_for: string, role_id: string) =>
      dispatch(roleActions.fetchRolePermissions(permission_for, role_id)),
    toggleRolePermissionStatus: (
      permission_for: string,
      role_id: string,
      resource: string,
      action: string,
      is_active: boolean
    ) =>
      dispatch(
        roleActions.toggleRolePermissionStatus(
          permission_for,
          role_id,
          resource,
          action,
          is_active
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
