import { print } from "graphql";
import axios from "../../axios-client";
import gql from "graphql-tag";
import { parseErrors } from "../helpers";
import {
  NewCustomerContractInput,
  GraphqlError,
  AutnhiveContractType,
  ProductType,
  PartnerType,
  RenewCustomerContractInput,
  PackageType,
  FeatureType,
  FeatureInput,
  PackageInput,
  PackageListType,
} from "../interfaces";
import moment from "moment";
import { env } from '../../env';
import qs from "qs";
import { getLocalDate, getUTCDate, offset } from "../dates";

const apiUrl: string = env.REACT_APP_COMMON_API_URL;
// Date.prototype.toJSON = function(){ return moment(this).format(); }

export async function fetchInputOptions() {
  const query = gql`
    query newCustomerContractFormControls {
      newCustomerContractFormControls {
        products {
          id
          name
        }
        sales_people {
          id
          first_name
          last_name
          email
        }
        approvers{
          id
          first_name
          last_name
          email
        }
        packages {
          id
          package_code
          package_name
          pricing {
            currency
            value
          }
          features
          product_id
          priority
        }
        features{
          id
          package_code
          feature_code
          feature
          price {
            currency
            value
          }
          priority
          product_id
        }
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<InputOptionsResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: {},
      },{
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    
    return {
      products: data.data.newCustomerContractFormControls.products,
      salesPeople: data.data.newCustomerContractFormControls.sales_people,
      packages: data.data.newCustomerContractFormControls.packages,
      features: data.data.newCustomerContractFormControls.features,
      approvers: data.data.newCustomerContractFormControls.approvers,
    };
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    } else {
      throw err;
    }
  }
}

export async function fetchProducts() {
  const query = gql`
    query newCustomerContractFormControls {
      newCustomerContractFormControls {
        products {
          id
          name
        }
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<InputOptionsResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: {},
      },{
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    
    return {
      products: data.data.newCustomerContractFormControls.products
    };
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    } else {
      throw err;
    }
  }
}

export async function fetchContracts(
  {
    customer_name,
    status,
    sort
  }: {
    customer_name: string;
    status: "draft" | "approved" | "rejected" | undefined;
    sort: "create-date" | "start-date" | "name";
  },
  page: number,
  limit: number
): Promise<{ list: [AutnhiveContractType]; count: number }> {
  const query = gql`
    query contracts(
      $page: Int!
      $limit: Int!
      $customer_name: String
      $status: String
      $sort: String
    ) {
      autnhiveContracts(
        page: $page
        limit: $limit
        customer_name: $customer_name
        status: $status
        sort: $sort
      ) {
        list {
          id
          contract_id
          status
          sales_code
          sales_person
          package_code
          package_features
          contract_start_date
          contract_end_date
          is_self_signup
          trial_end_date
          is_avail_trial
          is_physical_contract
          is_prepaid
          available_credit
          total_subscriptions
          currency
          prepaid_details{
            payment_mode
            paid_at
            paid_amount
            remarks
            transaction_id
            is_partial_payment
            payment_from
            payment_to
          }
          physical_contract_details{
            accepted_by
            accepted_date
            approved_by
            approved_date
          }
          taxes {
            description
            rate
          }
          product {
            id
            name
          }
          invoice_deductions {
            description
            amount
            type
            start_date
            end_date
          }
          user_license_count
          device_license_count
          is_active
          is_editable
          customer {
            id
            type
            use_credit_card
            organization {
              id
              name
              email
              phone
              address {
                line_1
                line_2
                city
                state
                postal_code
                country
              }
            }
            allowed_domains
            reseller {
              id
              name
              email
              phone
              address {
                line_1
                line_2
                city
                state
                postal_code
                country
              }
              is_white_label
              white_label_url
            }
            primary_contact {
              first_name
              last_name
              email
            }
          }
          status
          draft_details {
            customer {
              name
              customer_type
              use_credit_card
              email
              phone
              address {
                line_1
                line_2
                city
                state
                postal_code
                country
              }
            allowed_domains
            is_white_label
            white_label_url
          }
            user {
              first_name
              last_name
              email
            }
            subscription {
              device_license_count
              user_license_count
            }
          }
          partner {
            id
            name
            email
            phone
            address {
              line_1
              line_2
              city
              state
              postal_code
              country
            }
          }
          approved_date
          approved_by {
            id
            first_name
            last_name
            email
          }
          terms_agreed_by {
            id
            first_name
            last_name
            email
          }
          terms_agreed_on
          skip_user_terms
        }
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<ContractsResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: { limit, page, customer_name, status, sort },
    },{
      headers: {
        "timestamp": `${timeStamp}`,
      }
    });
    return data.data.autnhiveContracts;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

const contractFragment = gql`
  fragment contract_fragment on AutnhiveContractTermType {
    id
    status
    product {
      id
      name
    }
    sales_code
    sales_person
    contract_end_date
    contract_start_date
    trial_end_date
    currency
    taxes {
      description
      rate
    }
    invoice_deductions {
      description
      amount
      type
      start_date
      end_date
    }
    draft_details {
      customer {
        name
        customer_type
        use_credit_card
        email
        phone
        address {
          line_1
          line_2
          city
          state
          postal_code
          country
        }
      }
      user {
        first_name
        last_name
        email
      }
      subscription {
        device_license_count
        user_license_count
      }
    }
    customer {
      id
      type
      use_credit_card
      organization {
        id
        name
      }
      reseller {
        id
        name
      }
    }
  }
`;

export async function createNewFeature(
  values: FeatureInput
): Promise<FeatureType> {
  const query = gql `
  mutation createNewFeature($values: FeatureInput!) {
    createNewFeature(input: $values) {
      id
      feature_code
      feature
      package_code
      product_id
      price {
        value
        currency
      }
    }
  }`

  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<CreateFeatureResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: { values: values },
      },{
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.createFeature;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function updatePackage(
  values: PackageInput
): Promise<PackageListType> {
  const query = gql `
  mutation updatePackage($values: PackageInput!) {
    updatePackage(input: $values) {
      id
      package_code
      package_name
      features
      product_id
      pricing {
        value
        currency
      }
      is_active
    }
  }`

  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<UpdatePackageResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: { values: values },
      },{
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.updatePackage;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function createContract(
  values: NewCustomerContractInput
): Promise<AutnhiveContractType> {
  const query = gql`
    mutation createContract($values: ContractInput!) {
      createContract(input: $values) {
        ...contract_fragment
      }
    }
    ${contractFragment}
  `;
  let newValues: any = { ...values, partner_id: values.partner?.id || null };
  if (values.customer_id) {
    newValues.existing_customer_details = { 
      customer_id: values.customer_id,
      user: values.user 
    };
  } else {
    newValues.new_customer_details = {
      user: values.user,
      customer: values.customer,
    };
  }
  delete newValues.user;
  delete newValues.customer_id;
  delete newValues.customer;
  delete newValues.partner;
  newValues.trial_end_date = getUTCDate(moment(newValues.trial_end_date));
  newValues.contract_start_date = getUTCDate(moment(newValues.contract_start_date));
  newValues.contract_end_date = getUTCDate(moment(newValues.contract_end_date));
  
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<CreateContractResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: { values: newValues },
      },{
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.createContract;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function renewContract(
  id: string,
  values: RenewCustomerContractInput
): Promise<AutnhiveContractType> {
  const query = gql`
    mutation renewContract($id: String!, $values: RenewContractInput!) {
      renewContract(id: $id, input: $values) {
        ...contract_fragment
      }
    }
    ${contractFragment}
  `;

  let newValues: any = { ...values, partner_id: values.partner?.id || null };
  delete newValues.partner;
  newValues.contract_start_date = getUTCDate(moment(newValues.contract_start_date));
  newValues.contract_end_date = getUTCDate(moment(newValues.contract_end_date));
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<RenewContractResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: { id, values: newValues },
      },{
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.renewContract;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function approveRejectContract(
  id: string,
  action: "approve" | "reject"
): Promise<AutnhiveContractType> {
  const query = gql`
    mutation approveRejectContract($id: String!, $action: String!) {
      approveRejectContract(id: $id, action: $action) {
        ...contract_fragment
      }
    }
    ${contractFragment}
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<ApproveRejectContractResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: { id, action },
      },{
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.approveRejectContract;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function updateContract(
  id: string,
  values: NewCustomerContractInput
): Promise<AutnhiveContractType> {
  const query = gql`
    mutation updateContract($id: String!, $values: ContractInput!) {
      updateContract(id: $id, input: $values) {
        ...contract_fragment
      }
    }
    ${contractFragment}
  `;
  let newValues: any = { ...values, partner_id: values.partner?.id || null };
  if (values.customer_id) {
    newValues.existing_customer_details = { 
      customer_id: values.customer_id, 
      use_credit_card: values.customer?.use_credit_card,
      user: values.user };
  } else {
    newValues.new_customer_details = {
      user: values.user,
      customer: values.customer,
    };
  }
  delete newValues.user;
  delete newValues.customer_id;
  delete newValues.customer;
  delete newValues.partner;
  newValues.trial_end_date = getUTCDate(moment(newValues.trial_end_date));
  newValues.contract_start_date = getUTCDate(moment(newValues.contract_start_date));
  newValues.contract_end_date = getUTCDate(moment(newValues.contract_end_date));
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<UpdateContractResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: { id, values: newValues },
      },{
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.updateContract;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function fetchDashboardDetails() : Promise<{contracts:string, customers:string,
  users:string,organizations:string, invoices: { nos:string, total:string }, 
  products: [{id:string, name:string, internal_product_id:number}],
  packages: [{id:string, package_code:string, package_name:string,features:[string],
    pricing:[{currency:string, value:string}],product_id:string, is_active:boolean, priority:number}],
  features: [{id:string, feature_code:string, feature:string, price:[{currency:string, value:string}], product_id:string}]}> {
  const query = gql `
  query dashboardDetails{
    dashboardDetails{
      contracts
      customers
      users
      organizations
      invoices {
        nos
        total
      }
      products {
        id
        name
        internal_product_id
      }
      packages {
        id
        package_code
        package_name
        features
        pricing {
          currency
          value
        }
        product_id
        is_active
        priority
      }
      features {
        id
        feature_code
        feature
        price {
          currency
          value
        }
        product_id
      }
    }
  }`
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<DashboardResponse>(`${apiUrl}/graphql`, {
      query: print(query),
    },{
      headers: {
        "timestamp": `${timeStamp}`,
      }
    });
    return data.data.dashboardDetails;
  } catch (err: any) {
    console.log("err", err);
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function fetchPartners(
  { keyword }: { keyword?: string },
  page: number,
  limit: number
): Promise<{ list: [PartnerType]; count: number }> {
  const query = gql`
    query contractFormControlPartners($limit: Int!, $page: Int!) {
      contractFormControlPartners(limit: $limit, page: $page) {
        list {
          id
          name
        }
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<PartnersResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: { limit, page, keyword },
    },{
      headers: {
        "timestamp": `${timeStamp}`,
      }
    });
    return data.data.contractFormControlPartners;
  } catch (err: any) {
    console.log("err", err);
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

interface PartnersResponse {
  data: {
    contractFormControlPartners: {
      list: [PartnerType];
      count: number;
    };
  };
}

interface DashboardResponse {
  data: {
    dashboardDetails: {
      contracts: string;
      customers: string;
      users: string;
      organizations: string;
      invoices: {
        nos: string;
        total: string;
      };
      products: [{
        id: string;
        name: string;
        internal_product_id: number;
      }],
      packages: [{
        id:string, 
        package_code:string, 
        package_name:string,
        features:[string],
        pricing:[{
          currency:string, 
          value:string
        }],
        is_active:boolean,
        priority:number,
        product_id:string}],
      features: [{
        id:string, 
        feature_code:string, 
        feature:string, 
        price:[{
          currency:string, 
          value:string
        }], 
        product_id:string}]
    }
  }
}

interface InputOptionsResponse {
  data: {
    newCustomerContractFormControls: {
      products: [ProductType];
      sales_people: [
        {
          id: string;
          first_name: string;
          last_name: string;
          email: string;
        }
      ];
      packages: [PackageType];
      features: [FeatureType];
      approvers: [
        {
          id: string;
          first_name: string;
          last_name: string;
          email: string;
        }
      ];
    };
  };
}

interface ContractsResponse {
  data: {
    autnhiveContracts: {
      list: [AutnhiveContractType];
      count: number;
    };
  };
}

interface CreateContractResponse {
  data: {
    createContract: AutnhiveContractType;
  };
  errors?: Array<GraphqlError>;
}

interface CreateFeatureResponse {
  data: {
    createFeature: FeatureType;
  };
  errors?: Array<GraphqlError>;
}

interface UpdatePackageResponse {
  data: {
    updatePackage: PackageListType;
  };
  errors?: Array<GraphqlError>;
}

interface RenewContractResponse {
  data: {
    renewContract: AutnhiveContractType;
  };
  errors?: Array<GraphqlError>;
}

interface ApproveRejectContractResponse {
  data: {
    approveRejectContract: AutnhiveContractType;
  };
  errors?: Array<GraphqlError>;
}

interface UpdateContractResponse {
  data: {
    updateContract: AutnhiveContractType;
  };
  errors?: Array<GraphqlError>;
}
