import * as React from "react";
import { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@mui/material";
import { Pagination } from "@mui/material";
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { EasaPageReducer } from "../reducers/autnhive";
import { Reducers } from "../reducers";
import * as easaActions from "../actions/easa";
import moment from "moment";
import { DomainsList } from "../services/interfaces";
import { Visibility } from "@mui/icons-material";
import no_image from "../assets/images/no_image.png";

const LIMIT = 20;

export default function Easa() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState<string>("");
  const { domains, loading }: EasaPageReducer = useSelector<
    Reducers,
    EasaPageReducer
  >((state) => state.autnhive.easa);

  useEffect(() => {
    dispatch(easaActions.fetchDomainsList(1, LIMIT));
    return () => {
      dispatch(easaActions.resetStates());
    };
  }, [dispatch]);

  return (
    <div>
      <Typography component="h1" variant="h5">
        EASA
      </Typography>

      <div className="mb-2">
        <Grid container spacing={2}>
          <Grid item>
            <TextField
              size="small"
              label="Search Domain"
              variant="outlined"
              placeholder="Press Enter to search"
              onChange={(e) => {
                setSearchText(e.target.value);
                // dispatch(organizationActions.updateSearchKeyword(e.target.value));
              }}
              onKeyPress={(e) => {
                if (e.key !== "Enter") {
                  return;
                }
                dispatch(
                  easaActions.fetchDomainsList(domains?.page, LIMIT, searchText)
                );
              }}
            />
          </Grid>
          <Grid item marginLeft={"auto"} marginRight={"20px"}>
            Number of Domains: <b>{domains?.count}</b>
          </Grid>
        </Grid>
        <div
          className="app-container domanis-table"
          style={{
            display: "flex",
            padding: "1rem",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <table className="domains-table">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th>Domain Name</th>
                <th>Status</th>
                <th>Created At</th>
                <th>First Run By</th>
                <th>EASA Score</th>
              </tr>
            </thead>
            {domains?.loading ? (
              <tbody>
                <tr>
                  <td colSpan={7}>
                    <CircularProgress size={26} color="inherit" />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {domains?.list.map((domain, index) => (
                  <tr
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(
                        easaActions.fetchDomainJobsList(1, LIMIT, domain?.url)
                      );
                      let obj = {
                        url: domain?.url,
                      };
                      localStorage.setItem(
                        "domainDetails",
                        JSON.stringify(obj)
                      );
                      navigate("job-details", {
                        state: {
                          logo: domain?.logo ? domain?.logo : '',
                          _id: domain?._id
                        }
                      });
                      e.stopPropagation();
                    }}
                  >
                    <td>{index + 1}</td>
                    <td>{domain?.logo ? <img src={domain?.logo } style={{width:'50px', height:'50px'}} /> : 
                    <img src={`https://logo.clearbit.com/${domain?.url}` } style={{width:'50px', height:'50px'}} onError={({ currentTarget }) => {
                      currentTarget.onerror = null; 
                      currentTarget.src= no_image;
                    }}/>}</td>
                    {/* <ImageNotSupportedIcon /> */}
                    <td style={{ padding: "15px 50px", textAlign: "left" }}>
                      {domain?.url}
                    </td>
                    <td>{domain?.status}</td>
                    <td>
                      {moment(domain?.created_at).format("DD-MMM-YYYY hh:mm A")}
                    </td>
                    <td>{domain?.created_by?.name}</td>
                    <td>{domain?.total_score || 0}</td>
                    {/* <td>{domain?.created_by?.name}</td> */}
                    {/* <td>
                        {moment(domain?.updated_at).format("DD-MMM-YYYY hh:mm A")}
                    </td> */}
                    {/* <td>
                      <Tooltip title="View Task">
                        <IconButton style={{ padding: 0 }} size="medium">
                          <Visibility />
                        </IconButton>
                      </Tooltip>
                    </td> */}
                  </tr>
                ))}
                {!domains?.list ||
                domains?.list.length === 0 ||
                domains.count === 0 ? (
                  <tr>
                    <td
                      colSpan={5}
                      style={{
                        fontStyle: "italic",
                        fontWeight: "normal",
                        color: "#343434",
                        padding: "0",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <span>No data available</span>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            )}
          </table>
        </div>
        {domains?.count > LIMIT && (
          <Pagination
            count={Math.ceil(domains?.count / LIMIT)}
            variant="outlined"
            shape="rounded"
            onChange={(e: React.ChangeEvent<unknown>, newPage: number) => {
              dispatch(easaActions.fetchDomainsList(newPage, LIMIT));
            }}
          />
        )}
      </div>
    </div>
  );
}
