import { print } from "graphql";
import axios from "../axios-client";
import gql from "graphql-tag";
import { parseErrors } from "./helpers";
import {
  UserType,
  GraphqlError,
  CreateUserFormInput,
  UserFormInput,
  RoleType,
  CreateRoleInput,
  UserSubscriptionType,
  RolePermissionType,
  UserBulkUploadType,
  UpdateUserProfileInput,
  PermissionType,
  SubscriptionType,
  LicenseType,
  CreateVendorLinkInput,
  CountryType,
} from "./interfaces";
import { env } from '../env';

const apiUrl: string = env.REACT_APP_COMMON_API_URL;

export async function fetchUsers(filters: {
  keyword?: string;
  organization_id?: string;
  limit: number;
  page: number;
}): Promise<{ list: Array<UserType>; count: number }> {
  const query = gql`
    query users(
      $organization_id: String
      $keyword: String
      $limit: Int!
      $page: Int!
    ) {
      users(
        limit: $limit
        page: $page
        organization_id: $organization_id
        keyword: $keyword
      ) {
        list {
          id
          first_name
          last_name
          email
          address {
            country
          }
          is_active
          is_deleted
          user_org_roles {
            id
            role {
              id
              name
            }
          }
          role {
            id
            name
          }
          organization {
            id
            name
          }
          active_directory_id
        }
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<UsersResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: filters,
    },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      });
    return data.data.users;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function updateUserProfile(
  values: UpdateUserProfileInput
): Promise<UserType> {
  const query = gql`
    mutation updateUserProfile(
      $first_name: String!
      $last_name: String!
      $address: MinimalAddressInput!
    ) {
      updateUserProfile(
        input: {
          first_name: $first_name
          last_name: $last_name
          address: $address
        }
      ) {
        id
        first_name
        last_name
        email
        address {
          country
        }
        role {
          id
          name
        }
        organization {
          id
          name
        }
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<UpdateUserProfileResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: values,
      },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    return data.data.updateUserProfile;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function fetchCountries(): Promise<Array<CountryType>> {
  const query = gql(`
      query {
        countries {
          id
          name 
          short_name
          country_code
          currency
        }
      }
    `)

    try {
      const timeStamp = new Date().getTime();
      const { data } = await axios.post<CountriesResponse>(
      `${ apiUrl }/graphql`,
      {
        query: print(query),
            },
      {
        headers: {
          "timestamp": `${timeStamp}`,
              }
      }
      );
  return data.data.countries;
} catch (err: any) {
  if (err.response?.data) {
    throw parseErrors(err.response?.data.errors);
  }
  throw err;
}
}

export async function createUser(
  values: CreateUserFormInput
): Promise<UserType> {
  const query = gql`
    mutation createUser(
      $first_name: String!
      $last_name: String!
      $email: String!
      $address: MinimalAddressInput!
      $roles: [UserRoleInput!]!
      $role_id: String!
      $organization_id: String!
      $subscription_id: String
    ) {
      createUser(
        input: {
          first_name: $first_name
          last_name: $last_name
          email: $email
          address: $address
          roles: $roles
          role_id: $role_id
          organization_id: $organization_id
        }
        subscription_id: $subscription_id
      ) {
        id
        first_name
        last_name
        email
        is_active
        address {
          country
        }
        role {
          id
          name
        }
        organization {
          id
          name
        }
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<CreateUserResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        address: values.address,
        role_id: values.user_roles ? values.user_roles[0].role_id : "",
        organization_id: values.organization_id,
        subscription_id: values.subscription_id,
        roles: values.user_roles
      },
    },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      });
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.user;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function userBulkUpload(
  organization_id: string,
  file: File,
  subscription_id?: string
): Promise<Array<UserBulkUploadType>> {
  const query = gql`
    mutation userBulkUpload(
      $organization_id: String!
      $file: Upload!
      $subscription_id: String
    ) {
      userBulkUpload(
        organization_id: $organization_id
        file: $file
        subscription_id: $subscription_id
      ) {
        success
        error
        data_input {
          first_name
          last_name
          email
          role_name
        }
      }
    }
  `;
  const formData: FormData = new FormData();
  formData.append(
    "operations",
    JSON.stringify({
      query: print(query),
      operationName: "userBulkUpload",
      variables: { file: null, organization_id, subscription_id },
    })
  );
  formData.append("map", JSON.stringify({ "0": ["variables.file"] }));
  formData.append("0", file);
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<UserBulkUploadResponse>(
      `${apiUrl}/graphql`,
      formData,
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.userBulkUpload;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function updateUser(
  id: string,
  values: UserFormInput
): Promise<UserType> {
  const query = gql`
    mutation updateUser(
      $id: String!
      $first_name: String!
      $last_name: String!
      $address: MinimalAddressInput!
      $roles: [UserRoleInput!]!
      $is_active: Boolean!
      $role_id: String!
    ) {
      updateUser(
        id: $id
        input: {
          first_name: $first_name
          last_name: $last_name
          address: $address
          roles: $roles
          is_active: $is_active
          role_id: $role_id
        }
      ) {
        id
        first_name
        last_name
        email
        is_active
        address {
          country
        }
        role {
          id
          name
        }
        organization {
          id
          name
        }
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<UpdateUserResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: {
        id: id,
        first_name: values.first_name,
        last_name: values.last_name,
        address: values.address,
        is_active: values.is_active,
        role_id: values.user_roles ? values.user_roles[0].role_id : "",
        roles: values.user_roles
      },
    },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      });
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.updateUser;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function fetchRoles(
  limit: number,
  page: number,
  { organization_id, role_id, keyword }: { organization_id?: string, role_id?: string, keyword?: string }
): Promise<{ list: Array<RoleType>; count: number }> {
  const query = gql`
    query roles($organization_id: String, $role_id: String, $keyword: String, $limit: Int!, $page: Int!) {
      roles(organization_id: $organization_id, role_id: $role_id, keyword: $keyword, limit: $limit, page: $page) {
        list {
          id
          name
          role_for
          is_builtin
          organization {
            id
            name
            organization_type
          }
          reseller {
            id
            name
          }
          product {
            id
            name
          }
        }
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<RolesResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: { limit, page, organization_id, role_id, keyword },
    },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      });
    return data.data.roles;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function createRole(values: CreateRoleInput): Promise<RoleType> {
  const query = gql`
    mutation createRole(
      $name: String!
      $role_for: String!
      $organization_id: String
      $reseller_id: String
      $product_id: String
    ) {
      createRole(
        input: {
          name: $name
          role_for: $role_for
          organization_id: $organization_id
          reseller_id: $reseller_id
          product_id: $product_id
        }
      ) {
        id
        name
        role_for
        organization {
          id
          name
        }
        reseller {
          id
          name
        }
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<CreateRoleResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: {
        name: values.name,
        role_for: values.role_for,
        organization_id: values.organization_id,
        reseller_id: values.reseller_id,
        product_id: values.product_id
      },
    },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      });
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.role;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function createVendorLink(values: CreateVendorLinkInput): Promise<RoleType> {

  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<CreateRoleResponse>(`${apiUrl}/vendor/create`, {
      variables: values,
    },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      });
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.role;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function updateRole(
  id: string,
  values: { name: string }
): Promise<RoleType> {
  const query = gql`
    mutation updateRole($id: String!, $name: String!) {
      updateRole(id: $id, input: { name: $name }) {
        id
        name
        role_for
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<UpdateRoleResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: {
        id,
        name: values.name,
      },
    },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      });
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.role;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function fetchUserSubscriptions(
  organization_id: string,
  user_id: string,
  email: string
): Promise<Array<UserSubscriptionType>> {
  const query = gql`
    query userSubscriptions(
      $organization_id: String!
      $user_id: String!
      $email: String!
    ) {
      subscriptions(organization_id: $organization_id, limit: 1000, page: 1) {
        list {
          id
          is_active
          subscription_type
          product {
            id
            name
          }
        }
      }
      userSubscriptions(user_id: $user_id) {
        id
        is_active
        subscription {
          id
          is_active
          subscription_type
          product {
            id
            name
          }
        }
      }
      licenses(uid: $email) {
        id
        uid
        device_name
        subscription_id
        is_active
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<UserSubscriptionResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: { organization_id, user_id, email },
      },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    const subscriptions: Array<UserSubscriptionType> = [];
    data.data.subscriptions?.list.forEach((op) => {
      if (!op.is_active) return;
      const userSubscription = data.data.userSubscriptions?.find(
        (us) => op.id === us.subscription.id
      );
      const licenses = data.data.licenses?.filter(
        (l) => l.subscription_id === op.id
      );
      subscriptions.push({
        id: op.id,
        user_subscription: userSubscription,
        organization: op.organization,
        subscription_type: op.subscription_type,
        subscription: op,
        licenses,
      });
    });
    return subscriptions;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function createUserSubscription(
  user_id: string,
  subscription_id: string
): Promise<UserSubscriptionType> {
  const query = gql`
    mutation createUserSubscription(
      $user_id: String!
      $subscription_id: String!
    ) {
      createUserSubscription(
        user_id: $user_id
        subscription_id: $subscription_id
        is_active: true
      ) {
        id
        is_active
        subscription {
          id
        }
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<CreateUserSubscriptionResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: { user_id, subscription_id },
      },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.createUserSubscription;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function updateUserSubscriptionStatus(
  id: string,
  is_active: boolean
): Promise<UserSubscriptionType> {
  const query = gql`
    mutation updateUserSubscriptionStatus($id: String!, $is_active: Boolean!) {
      updateUserSubscriptionStatus(id: $id, is_active: $is_active) {
        id
        is_active
        subscription {
          id
        }
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<UpdateUserSubscriptionStatusResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: {
          id,
          is_active,
        },
      },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.updateUserSubscriptionStatus;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function deleteRole(id: string) {
  const query = gql`
    mutation deleteRole($id: String!) {
      deleteRole(id: $id)
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<{
      data: { deleteRole: boolean };
      errors?: Array<GraphqlError>;
    }>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: { id },
    },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      });
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.deleteRole;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function fetchRolePermissions(
  permission_for: string,
  role_id: string
): Promise<Array<RolePermissionType>> {
  const query = gql`
    query getPermissionsByRoleId($permission_for: String!, $role_id: String!) {
      permissions(permission_for: $permission_for) {
        id
        resource
        action
        description
        is_autnhive
      }
      rolePermissions(role_id: $role_id) {
        id
        permission {
          id
        }
        role {
          id
          name
          role_for
        }
        is_active
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<FetchRolePermissionsResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: {
          permission_for,
          role_id,
        },
      },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    const permissions: Array<RolePermissionType> = [];
    data.data.permissions.forEach((permission) => {
      const rolePermission = data.data.rolePermissions.find(
        (rp) => rp.permission.id === permission.id
      );

      permissions.push({
        id: rolePermission?.id,
        role: rolePermission?.role,
        is_active: rolePermission?.is_active || false,
        permission,
      });
    });
    return permissions;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function upsertRolePermission(
  role_id: string,
  resource: string,
  action: string,
  is_active: boolean
): Promise<RolePermissionType> {
  const query = gql`
    mutation upsertRolePermission(
      $role_id: String!
      $resource: String!
      $action: String!
      $is_active: Boolean!
    ) {
      upsertRolePermission(
        role_id: $role_id
        resource: $resource
        action: $action
        is_active: $is_active
      ) {
        id
        permission {
          id
        }
        is_active
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<UpdateRolePermissionResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: {
          role_id,
          resource,
          action,
          is_active,
        },
      },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    return data.data.upsertRolePermission;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function downloadSampleUploadFile(orgType: string) {
  const link = document.createElement("a");
  document.body.appendChild(link);
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.get(
      `${apiUrl}/files/sample-bulk-user-upload-file?type=${orgType}`, { headers: { "timestamp": `${timeStamp}` } }
    );
    link.setAttribute(
      "href",
      URL.createObjectURL(
        new Blob([data], {
          type: "text/csv",
        })
      )
    );
    link.setAttribute("download", "uploadfile.csv");
    link.click();
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  } finally {
    document.body.removeChild(link);
  }
}

interface UpdateUserProfileResponse {
  data: {
    updateUserProfile: UserType;
  };
  errors?: Array<GraphqlError>;
}

interface UsersResponse {
  data: {
    users: {
      list: [UserType];
      count: number;
    };
  };
}

interface CreateUserResponse {
  data: {
    user: UserType;
  };
  errors?: Array<GraphqlError>;
}

interface UpdateUserResponse {
  data: {
    updateUser: UserType;
  };
  errors?: Array<GraphqlError>;
}

interface RolesResponse {
  data: {
    roles: {
      list: [RoleType];
      count: number;
    };
  };
}

interface CreateRoleResponse {
  data: {
    role: RoleType;
  };
  errors?: Array<GraphqlError>;
}

interface UpdateRoleResponse {
  data: {
    role: RoleType;
  };
  errors?: Array<GraphqlError>;
}

interface UserSubscriptionResponse {
  data: {
    id: string;
    subscriptions?: {
      list: Array<SubscriptionType>;
    };
    userSubscriptions?: Array<{
      id: string;
      is_active: boolean;
      subscription: SubscriptionType;
    }>;
    licenses?: Array<LicenseType>;
  };
}

interface CreateUserSubscriptionResponse {
  data: {
    createUserSubscription: UserSubscriptionType;
  };
  errors?: Array<GraphqlError>;
}

interface UpdateUserSubscriptionStatusResponse {
  data: {
    updateUserSubscriptionStatus: UserSubscriptionType;
  };
  errors?: Array<GraphqlError>;
}

interface FetchRolePermissionsResponse {
  data: {
    permissions: [PermissionType];
    rolePermissions: Array<{
      id: string;
      permission: PermissionType;
      role: RoleType;
      is_active: boolean;
    }>;
  };
}

interface UpdateRolePermissionResponse {
  data: {
    upsertRolePermission: RolePermissionType;
  };
  errors?: Array<GraphqlError>;
}

interface UserBulkUploadResponse {
  data: {
    userBulkUpload: Array<UserBulkUploadType>;
  };
  errors?: Array<GraphqlError>;
}

interface CountriesResponse {
  data: {
    countries: Array<CountryType>;
  };
  errors?: Array<GraphqlError>;
}
